import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const alajaamadeSusteem = () => (
  <Layout>
    <SEO title="Alajaamade süsteem" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>ALAJAAMADE SÜSTEEM</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link
          to="/tostetudporandad/alajaamade-susteem/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo">
            <h2>Alajaamade süsteem</h2>
          </div>
        </Link>
        <Link to="/tostetudporandad/kaltsiumsulfaat-paneelid/">
          <div className="epo">
            <h2>Kaltsiumsulfaat-paneelid</h2>
          </div>
        </Link>
        <Link to="/tostetudporandad/puitlaastpaneelid/">
          <div className="epo">
            <h2>Puitlaastpaneelid</h2>
          </div>
        </Link>
      </div>

      <div className="alajaamade-wrapper">
        <div className="alajaamade-text">
          <h3>Alajaama süsteem</h3>
          <p>
            Alajaamade süsteemi puhul kasutatakse tõstpõrandatel terasest
            rist-alusraamistikku, mis on oma konstruktsioonilt eriti tugev ning
            stabiilne. Alusraamistik moodustatakse peamiselt 40 x 80 x 2 mm
            mõõduga tsingitud pinnaga nelikanttorudest. Stabiilsete talade ja
            postide lahenduse tõttu säilib tõstpõranda stabiilsus ja tugevus ka
            siis kui põrandalt eemaldada kõik kattepaneelid. Alusraamistiku
            tugevust on hõlpsalt võimalik lisada ka hiljem ruumi ekspluatsiooni
            ajal.
          </p>
        </div>
        <div className="subpage-img alajaamade-img"></div>
      </div>
    </div>
  </Layout>
)

export default alajaamadeSusteem
